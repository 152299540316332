var exports = {};

exports = function (hljs) {
  return {
    aliases: ["adoc"],
    contains: [// block comment
    hljs.COMMENT("^/{4,}\\n", "\\n/{4,}$", // can also be done as...
    //'^/{4,}$',
    //'^/{4,}$',
    {
      relevance: 10
    }), // line comment
    hljs.COMMENT("^//", "$", {
      relevance: 0
    }), // title
    {
      className: "title",
      begin: "^\\.\\w.*$"
    }, // example, admonition & sidebar blocks
    {
      begin: "^[=\\*]{4,}\\n",
      end: "\\n^[=\\*]{4,}$",
      relevance: 10
    }, // headings
    {
      className: "section",
      relevance: 10,
      variants: [{
        begin: "^(={1,5}) .+?( \\1)?$"
      }, {
        begin: "^[^\\[\\]\\n]+?\\n[=\\-~\\^\\+]{2,}$"
      }]
    }, // document attributes
    {
      className: "meta",
      begin: "^:.+?:",
      end: "\\s",
      excludeEnd: true,
      relevance: 10
    }, // block attributes
    {
      className: "meta",
      begin: "^\\[.+?\\]$",
      relevance: 0
    }, // quoteblocks
    {
      className: "quote",
      begin: "^_{4,}\\n",
      end: "\\n_{4,}$",
      relevance: 10
    }, // listing and literal blocks
    {
      className: "code",
      begin: "^[\\-\\.]{4,}\\n",
      end: "\\n[\\-\\.]{4,}$",
      relevance: 10
    }, // passthrough blocks
    {
      begin: "^\\+{4,}\\n",
      end: "\\n\\+{4,}$",
      contains: [{
        begin: "<",
        end: ">",
        subLanguage: "xml",
        relevance: 0
      }],
      relevance: 10
    }, // lists (can only capture indicators)
    {
      className: "bullet",
      begin: "^(\\*+|\\-+|\\.+|[^\\n]+?::)\\s+"
    }, // admonition
    {
      className: "symbol",
      begin: "^(NOTE|TIP|IMPORTANT|WARNING|CAUTION):\\s+",
      relevance: 10
    }, // inline strong
    {
      className: "strong",
      // must not follow a word character or be followed by an asterisk or space
      begin: "\\B\\*(?![\\*\\s])",
      end: "(\\n{2}|\\*)",
      // allow escaped asterisk followed by word char
      contains: [{
        begin: "\\\\*\\w",
        relevance: 0
      }]
    }, // inline emphasis
    {
      className: "emphasis",
      // must not follow a word character or be followed by a single quote or space
      begin: "\\B'(?!['\\s])",
      end: "(\\n{2}|')",
      // allow escaped single quote followed by word char
      contains: [{
        begin: "\\\\'\\w",
        relevance: 0
      }],
      relevance: 0
    }, // inline emphasis (alt)
    {
      className: "emphasis",
      // must not follow a word character or be followed by an underline or space
      begin: "_(?![_\\s])",
      end: "(\\n{2}|_)",
      relevance: 0
    }, // inline smart quotes
    {
      className: "string",
      variants: [{
        begin: "``.+?''"
      }, {
        begin: "`.+?'"
      }]
    }, // inline code snippets (TODO should get same treatment as strong and emphasis)
    {
      className: "code",
      begin: "(`.+?`|\\+.+?\\+)",
      relevance: 0
    }, // indented literal block
    {
      className: "code",
      begin: "^[ \\t]",
      end: "$",
      relevance: 0
    }, // horizontal rules
    {
      begin: "^'{3,}[ \\t]*$",
      relevance: 10
    }, // images and links
    {
      begin: "(link:)?(http|https|ftp|file|irc|image:?):\\S+\\[.*?\\]",
      returnBegin: true,
      contains: [{
        begin: "(link|image:?):",
        relevance: 0
      }, {
        className: "link",
        begin: "\\w",
        end: "[^\\[]+",
        relevance: 0
      }, {
        className: "string",
        begin: "\\[",
        end: "\\]",
        excludeBegin: true,
        excludeEnd: true,
        relevance: 0
      }],
      relevance: 10
    }]
  };
};

export default exports;